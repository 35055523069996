import React from 'react'
import { BiLoaderAlt } from "react-icons/bi";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const Loading = () => {
    return (
        <div className="flex justify-center items-center my-12">
            <span className="text-7xl text-gary-primary">
                <BiLoaderAlt className="animate-spin" />
            </span>
        </div>
    )
}

export default Loading

export const FullScreenLoading = () => {
    return (
        <div className="loader-screen left-0 top-0 fixed h-screen w-screen bg-white flex items-center justify-center" style={{ zIndex: 10000 }}>
            <h2 className="animate-spin text-7xl text-gary-primary"><BiLoaderAlt /></h2>
        </div>
    )
}




export const TableSkeletonLoading = () => {
    return (
        <>
            <div className='bg-white w-full px-4 py-3'>
                <div className=''>
                    <div className='grid grid-cols-3'>
                        <Skeleton count={1} duration={2} height={20} width={100} />
                        <Skeleton count={1} duration={2} height={20} width={100} />
                        <Skeleton count={1} duration={2} height={20} width={100} />
                    </div>
                </div>

                <div className='py-4'>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                        <div className=''>
                        <Skeleton count={1} duration={2} height={20} width={250} />
                        </div>
                        <div className='hidden md:block '>
                            <Skeleton count={1} duration={2} height={20} width={250} />
                        </div>
                        <div className='hidden lg:block'>
                            <Skeleton count={1} duration={2} height={20} width={250} />
                        </div>
                    </div>
                </div>
                <div className=''>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                        <div>
                            <Skeleton count={1} duration={2} height={20} width={250} />
                        </div>
                        <div className='hidden md:block '>
                            <Skeleton count={1} duration={2} height={20} width={250} />
                        </div>
                        <div className='hidden lg:block'>
                            <Skeleton count={1} duration={2} height={20} width={250} />
                        </div>
                    </div>
                </div>

                <div className='flex justify-end space-x-4 pt-5'>
                    <Skeleton count={1} duration={2} height={20} width={100} />
                    <Skeleton count={1} duration={2} height={20} width={80} />
                    <Skeleton count={1} duration={2} height={20} width={20} />
                    <Skeleton count={1} duration={2} height={20} width={20} />
                    <Skeleton count={1} duration={2} height={20} width={20} />
                    <Skeleton count={1} duration={2} height={20} width={20} />
                </div>

            </div>
        </>
    )
}


export const FilterSkeletonLoading = () => {
    return (
        <div className='flex justify-between  w-full bg-white rounded-md p-4 mb-2'>
            <div className='flex  space-x-4 w-full'>
                <div>
                    <Skeleton count={1} duration={2} height={20} width={250} />
                </div>
                <div className="hidden lg:block">
                    <Skeleton count={1} duration={2} height={20} width={250} />
                </div>
            </div>
            {/* <div>
                <Skeleton count={1} duration={2} height={20} width={250} />
            </div> */}
        </div>
    )
} 