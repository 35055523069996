import { createSlice } from '@reduxjs/toolkit';


const initialState={
        products:[] ,
        productsOptions:[],
        selected_product:""      
}


const getProductsForHomePage = createSlice({
    name: "Home Products",
    initialState,
    reducers: {
        set_products: (state, action) => {
                state.products =  [...state.products, ...action.payload];
                return state;
        },
        set_products_options: (state, action) => {
            state.productsOptions =  [...state.productsOptions, ...action.productsOptions];
            return state;
        },
        selected_product: (state,action)=>{
            state.selected_product = action.payload
            return state
        },
        reset_products: (state,action)=>{
            state.products = initialState.products
            return state
        }
    }
})

export const {set_products,reset_products,selected_product,set_products_options} = getProductsForHomePage.actions;
export default getProductsForHomePage.reducer;
