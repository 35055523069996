import { createSlice } from '@reduxjs/toolkit';


const initialState={
        categories_data:[]       
}


const getCategoriesSlice = createSlice({
    name: "Categories",
    initialState,
    reducers: {
        set_categories: (state, action) => {
                state.categories_data =  [...state.categories_data, ...action.payload];
                return state;
        },
        add_category: (state,action)=>{
            state.categories_data = [...state.categories_data, action.payload]
            return state
        },
        reset_categories: (state,action)=>{
            state.categories_data = initialState.categories_data
            return state
        }
    }
})

export const {set_categories,add_category,reset_categories} = getCategoriesSlice.actions;
export default getCategoriesSlice.reducer;
