import {FiUserX, FiUsers,FiLogOut} from "react-icons/fi";
import {FaRegAddressCard} from "react-icons/fa";
import {RiTShirtLine} from "react-icons/ri";
import {RiLinksFill} from "react-icons/ri";
import {IoIosContacts} from "react-icons/io";
import {MdOutlineDashboard} from "react-icons/md";
import {RiCoupon3Line} from "react-icons/ri";
import { ImProfile} from "react-icons/im";
import {BsPatchQuestion, BsCashCoin,BsArrowBarLeft} from "react-icons/bs";
import {BUSINESS_ADMIN_ROUTE, BUSINESS_LICENSE_ROUTE, BUSINESS_REQUESTS_ROUTE, CONTACT_US_ROUTE, MATOMO_LINK, ORDERS_ROUTE} from '../constants'
import { FormattedMessage } from "react-intl";
import { HiOutlineUsers } from "react-icons/hi";
import { HiOutlineUserGroup } from "react-icons/hi";
import { GrLicense,GrKey } from "react-icons/gr";
import { HiOutlinePhoneIncoming } from "react-icons/hi";
import { IoKeyOutline } from "react-icons/io5";
import { FaRegBuilding } from "react-icons/fa";


export const adminLinks = [
    {
        heading: "General",
        links: [
            {
                id: 1,
                title: <FormattedMessage id="dashboard" defaultMessage="Dashboard" />,
                to:"/dashboard",
                icon:<MdOutlineDashboard />
            },

            {
                id: 2,
                title: <FormattedMessage id="cards" defaultMessage="Cards" />,
                to:"/cards",
                icon:<FaRegAddressCard/>
            },
            {
                id: 3,
                title: <FormattedMessage id="users" defaultMessage="Users" />,
                to:"/users",
                icon:<FiUsers/>
            },
            {
                id: 4,
                title: <FormattedMessage id="product" defaultMessage="Product" />,
                to:"/products",
                icon:<RiTShirtLine/>
            },
            {
                id:5,
                title: <FormattedMessage id="social.link" defaultMessage="Social Links" />,
                to:"/social-links",
                icon:<RiLinksFill/>
            },

            
        ]
    },
    {
        heading: "Business",
        links: [
            {
                id: 6,
                title: <FormattedMessage id="business_requests" defaultMessage="Business Requests" />,
                to:BUSINESS_REQUESTS_ROUTE,
                icon:<MdOutlineDashboard />
            },
            {
                id: 7,
                title: <FormattedMessage id="business_companies" defaultMessage="Business Companies" />,
                to:BUSINESS_LICENSE_ROUTE,
                icon:<FaRegBuilding />
            },
            {
                id: 8,
                title: <FormattedMessage id="business_admins" defaultMessage="Business Admins" />,
                to:BUSINESS_ADMIN_ROUTE,
                icon:<HiOutlineUserGroup />
            },
            {
                id: 9,
                title: <FormattedMessage id="contact_us" defaultMessage="Contact Us" />,
                to:CONTACT_US_ROUTE,
                icon:<HiOutlinePhoneIncoming />
            },

        ]
    },
    {
        heading: "Tracking",
        links: [
           
            {
                id: 10,
                title: <FormattedMessage id="orders" defaultMessage="Orders" />,
                to: ORDERS_ROUTE,
                icon:<BsPatchQuestion/>
            },
        ]
    },

    {
        heading: "More",
        links: [
            {
                id: 11,
                title: <FormattedMessage id="coupon_codes" defaultMessage="Coupon Codes" />,
                to:"/coupon-codes",
                icon:<RiCoupon3Line/>
            },
            {
                id: 12,
                title: <FormattedMessage id="my-profile" defaultMessage="My Profile" />,
                to:"/profile",
                icon:<ImProfile/>
            },
            {
                id: 13,
                title: <FormattedMessage id="logout" defaultMessage="Logout" />,
                to:"",
                logout:true,
                icon:<BsArrowBarLeft/>
            }
           
        ]
    },
    
    
]