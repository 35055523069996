import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    add_value_data:[]
}


const addAttributeValueSlice = createSlice({
    name: "Add_Attribute_Value",
    initialState,
    reducers: {
        set_attribute_select_value: (state, action)=>{
            state.add_value_data = [...state.add_value_data, ...action.payload]
            return state
        },
        add_attribute_value:(state,action)=>{
            state.add_value_data = [...state.add_value_data, action.payload]
            return state
        },
        reset_attribute_select_value:(state,actions) => {
            state.add_value_data = initialState.add_value_data
            return state
        }
    }
})

export const {add_attribute_value,set_attribute_select_value,reset_attribute_select_value} = addAttributeValueSlice.actions;
export default addAttributeValueSlice.reducer;
