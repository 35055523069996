import React, { useState } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { logout } from '../../redux/slices/userSlice';
import { error_alert, removeToken, success_alert } from '../../utils';
import { Axios } from '../../api';
import { useIntl } from 'react-intl';
import {MdLanguage} from 'react-icons/md';
import { IoIosArrowDropdown } from "react-icons/io";
import { AnimatePresence, motion } from 'framer-motion';
import LanguageSelection from '../TopBar/LanguageSelection';
import { useDetectClickOutside } from 'react-detect-click-outside';

const Sidebar = ({links,aside, setAside}) => {

    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const intl=useIntl()

    const [clickDropDown,setClickDropDown]=useState(false)
    const language = useSelector(state => state.language);
    const languageCardRef = useDetectClickOutside({onTriggered:()=>{setClickDropDown(false)}})


    const handleLogout = async () => {
        try {
          const result = await Axios.get("api/user/logout/admin");
          removeToken();
          dispatch(logout());
          success_alert(intl.formatMessage({id:"logout.success",defaultMessage:"You are logout Successfully."}))
        } catch (e) {
          if (e.response) {
            error_alert(e.response.data.description);
          } else {
            error_alert(intl.formatMessage({id:"network.error",defaultMessage:"Network Error"}));
          }
        }
    };

    return (
        <>
        <div className={`transition transform ${!aside ? "-translate-x-full" : "translate-x-0"} lg:translate-x-0 fixed top-0 left-0 bottom-0 w-64 lg:w-[270px]  bg-white border-r-2 border-gray-100 overflow-y-auto z-[10000000]`}>
            <div className=" px-4">
                <img src="/images/logo-horizontal.svg" alt="logo" className="w-64" />
            </div>
            <div className='mt-8'>
            {links.map((container,index) => (
                <>
                {container.heading &&
                <span key={index} className="px-7 py-4 block text-xs font-bold  uppercase text-gray-text">
                    {container.heading}
                </span>}

                <div className="space-y-3 px-4">
                {container.links.map((link, i) => (
                    
                        !link.logout && !link.analytics ?
                        <Link key={i.id} to={link.to} 
                        className={`${pathname === link.to ? "bg-theme-primary":""} transition-all flex cursor-pointer items-center hover:bg-theme-primary hover:text-white inline-block rounded-lg py-3 px-3 w-auto ${pathname === link.to ? "text-white":"text-black"} `}>
                            <span className="pr-3 text-lg">{link.icon}</span>
                            <p className="text-sm font-medium">{link.title}</p>
                        </Link>
                        :
                        <p key={i.id}  
                        onClick={handleLogout}
                        className={`w-full transition-all flex cursor-pointer items-center hover:bg-theme-primary hover:text-white inline-block rounded-md py-3 px-3 w-auto text-black `}>
                            <span className="pr-3 text-lg">{link.icon}</span>
                            <p className="text-sm font-medium">{link.title}</p>
                        </p>
                ))}
                </div> 
                </>
            ))}
            </div>
            {/* <div className="space-y-2 px-4 relative flex bg-red-100 justify-center">
                {/* <div className={`hover:bg-theme-secondary transition-all flex cursor-pointer items-center hover:bg-theme-secondary hover:text-white inline-block rounded-lg py-3 px-3 w-auto hover:text-white}`}> */}
                
                    {/* <MdLanguage  className=" text-3xl cursor-pointer "/>
                    <img className="absolute left-0 w-5" 
                      src={language.flag}
                /> */}
                {/* </div> */}
            {/* </div> */} 

            {/* <div className=" px-2 pr-3  py-4 w-full">
                <div className="flex gap-4 justify-between items-center">
                    <div className='flex gap-4 justify-between items-center'>
                    <div className="relative ">
                    {
                      language&&
                      <img className="absolute -mt-2  w-5" 
                      src={language.flag}
                       />
                    }
                      <span ><MdLanguage  className="ml-3 hover:text-red-600 text-xl text-black cursor-pointer "/></span>
                    </div>
                    {
                      language&&
                      <p className='text-start'>{language.title?language.title:"ENGLISH"}</p>
                    }
                    </div>
                    <IoIosArrowDropdown className="text-xl text-black justify-center cursor-pointer" ref={languageCardRef} onClick={()=>setClickDropDown(!clickDropDown)} />
                </div>
                
            </div> 
            {
                clickDropDown&&
                 <AnimatePresence exitBeforeEnter>
                    <div className='text-center px-2 w-ful' >
                        <LanguageSelection setClickDropDown={setClickDropDown}/>
                    </div>
                 </AnimatePresence>
            } */}
            
        </div>   
        {aside &&
            <div className="fixed  bg-black bg-opacity-25 top-0 bottom-0 left-0 right-0 z-[100027]" onClick={() => setAside(false)}></div>
        }
        </>
    )
}

export default Sidebar
