import React,{useState} from 'react';
import { BrowserRouter } from 'react-router-dom';
import BaseRoutes from './routes/BaseRoute';

import { Provider , useSelector} from 'react-redux';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import { IntlProvider } from 'react-intl';
import en from './languages/en.json';

import BasePopup,{ BasePopupContext} from './components/BasePopup';
import GetLanguage from './components/GetLanguage';
import DetectOffline from './components/DetectOffline';

function App() {
  // state for popup
  const selectedLanguage = useSelector(state => state.language)

  const initialPopupState = {
    component: "",
    visible: false,
    size: "small",
    heading: "",
    contained: false,
    hideDefaultClose: false
  }
  const [popupState, setPopupState] = useState(initialPopupState);

  const openPopup = (params) => setPopupState(old => ({...old, ...params}));

  const global_popup_context_data = {
    popupState,
    setPopup: openPopup
  }

  return (
    <>
    <BasePopupContext.Provider value={global_popup_context_data}>
        <BrowserRouter>
          <ReactNotifications />
          <IntlProvider messages={selectedLanguage.message} locale={selectedLanguage.locale}  defaultLocale="en">
          {/* main application routes here */}
          {/* <GetLanguage> */}
            <BaseRoutes />
          <BasePopup/>
          <DetectOffline/> 
          {/* </GetLanguage> */}
          
          {/* main application routes here */}
          </IntlProvider>
        </BrowserRouter>
    </BasePopupContext.Provider>
    </>
  );
}

export default App;
