
// Libraries Import
import React, {useState,Suspense} from 'react';
import {useSelector} from 'react-redux';
import { Route,Routes } from "react-router";

// Routes Import
import AuthRoutes from '../routes/AuthRoute';
import AdminRoutes from '../routes/AdminRoutes';

// import UserRoutes from '../routes/LocalAdminRoutes';

// // Components Import
import Sidebar from '../components/Sidebar/Sidebar';

import TopBar from '../components/TopBar/Topbar';
import Footer from '../components/Footer';
import {FullScreenLoading} from "../components/Loading";

// users links
import { adminLinks } from './adminLinks';
import { AnimatePresence, motion } from 'framer-motion';


import LanguageSelection from '../components/TopBar/LanguageSelection';



function BaseRoute({setSelectedLanguage,selectedLanguage}) {

  const [languageCard, setLanguageCard] = useState(false);
  const [aside, setAside] = useState(false);

  const user = useSelector(state => state.user);
  let links = adminLinks;
  
  return (  
    <div className="flex flex-col h-full">
      
      
      <Suspense fallback={<FullScreenLoading/>}>
        {/* if user is not logged-in we will show him auth 
        routes which contains login form */}
        
          {!user ? (
            <>
            <div className="w-full h-full flex flex-col justify-between">
                <AuthRoutes />
            </div> 
            </>
          ):null
          }

          {/* if user is logged-in we will show him other routes based on user role with topbar and sidebar*/}
          
          {user ? (
            <>
            <TopBar setAside={setAside} languageCard={languageCard} setLanguageCard={setLanguageCard}
            />
            <AnimatePresence exitBeforeEnter>
            {
                languageCard ?
                <LanguageSelection setSelectedLanguage={setSelectedLanguage}/>
                :
                null
            }
        </AnimatePresence>
              <>
              <Sidebar aside={aside} setAside={setAside}links={links}/>
              
              <div className={`w-full h-full flex flex-col justify-between pl-0 lg:pl-[270px] pt-16 lg:pt-0`}>

                <div className="flex-grow border-1 shadow-md  relative w-full">
                  <div className="">
                    <AdminRoutes />
                  </div>
                </div>
              </div>
              {/* <Footer/> */}
            </>
            </>
          ): null}
        </Suspense>
        
      </div>
  )
}

export default BaseRoute
