import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from "react-router";


import {FullScreenLoading} from "../components/Loading";

const Login = React.lazy(() => import("../pages/Login"));
const ForgotPass = React.lazy(() => import("../pages/ForgotPassword"));
const ResetPassword = React.lazy(() => import("../pages/ResetPassword"));


function AuthRoute() {

    return (
    <Suspense fallback={<FullScreenLoading/>}>
        <Routes>
            <Route path="/auth" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPass />} />
            <Route path="/reset-password/:key" element={<ResetPassword />} />
            
            <Route path="*" element={<Navigate to="/auth" />} />
        </Routes>
    </Suspense>
    )
}

export default AuthRoute
