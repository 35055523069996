import { configureStore } from '@reduxjs/toolkit';

// Slice imports

// user 
import userReducer from './slices/userSlice';
// user 

// 
import AddProductReducer from './slices/AddProductSlice';
// 

// 
import addAttributeValueReducer from './slices/AddAttributeValue'
// 

// 
import CategoriesReducer from './slices/getCategoriesSlice';

// 
import TagsReducer from './slices/getTagsSlice';
// 

// 
import CustomAttribuesReducer from "./slices/getCustomAttributes"
// 

// 
import AddquestionReducer from './slices/AddquestionSlice';
// 

// 
import languageReducer from './slices/languageSlice';
// 
// 
import HomePageReducer from './slices/EditHomePageSlice';
// 

import OrderDetailReducer from './slices/OrderDetailSlice';

import updateProductReducer from './slices/updateProductSlice';

// Slice imports




export default configureStore({
    reducer:{
        // auth user
        user: userReducer,
        // auth user

        // Add product
        product_data:  AddProductReducer,
        // Add product

        // add arrays of object
        addAttributeValue: addAttributeValueReducer,
        // add arrays of object
        
        // categories
        categories: CategoriesReducer,
        // categories

        // tags
        tags:TagsReducer,
        // tags
        
        // Custom attributes
        custom_attributes:CustomAttribuesReducer,
        // Custom attributes

        // Custom attributes
        add_question:AddquestionReducer,
        // Custom attributeslanguage:languageReducer,

        //language
        language:languageReducer,
        // languag

        //home page products
        home_page:HomePageReducer,
        //home page products

        order:OrderDetailReducer,
        update_product: updateProductReducer

    },
    devTools: process.env.NODE_ENV !== 'production',
})


