import React, { useState } from 'react';
import "./TopBar.css";
// import {DateTime} from "luxon";
// Icons Import
import { GiHamburgerMenu } from "react-icons/gi";
import { FaTimes } from "react-icons/fa";
import { AiFillCaretDown } from "react-icons/ai";
import {AiOutlineUser} from 'react-icons/ai';

// Components Import
import LogoutCard from './LogoutCard';
import {AnimatePresence} from 'framer-motion';

// detects click outside component
import { useDetectClickOutside } from 'react-detect-click-outside';

import { useSelector } from 'react-redux';
import {MdLanguage} from 'react-icons/md';



function Topbar({setAside}) {

    return (
        <div className="lg:hidden fixed top-0 w-full lg:pl-[270px] z-[60]">
        <div className = "lg:pr-4 flex-shrink-0 flex bg-white shadow-sm text-white justify-between w-full items-center relative h-16 lg:h-20">

            {/* hamburger for small screen */}
            <div className="bg-gray-bg-dark lg:hidden flex items-center h-full px-5 cursor-pointer" 
            onClick={() => setAside(old => !old)}
            >
                <GiHamburgerMenu className="text-2xl text-black" />
            </div>    
        </div>
            
        </div>
    )
}

export default Topbar
