import { createSlice } from '@reduxjs/toolkit';

import { getAuthUser } from '../../utils';



const initialState = getAuthUser();


const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        login: (state, action) => (action.payload),
        logout: (state,action) => {
            // localStorage.removeItem('access_token');
            return false;
        },
        update_name:(state,action)=>{
            console.log("sdkjhfgskdjh",action.payload)
            console.log("state",state)
            state.username = action.payload
            return state
        }

    }
})

export const {login,logout, update_name} = userSlice.actions;
export default userSlice.reducer;
