import React from 'react';

import en from '../../languages/en.json';
import it from '../../languages/it.json';

import { useDispatch } from 'react-redux';
import { set_language } from '../../redux/slices/languageSlice';


const LanguageSelection = ({setClickDropDown}) => {

    const dispatch = useDispatch() 
    const langListStyle = "py-2 px-2 border-b-[1px] hover:bg-red-600 hover:text-white rounded-md cursor-pointer flex items-center"
    const languages = [
      {
        title:"English",
        message:en,
        locale:"en",
        flag:"/images/lang-flags/uk.png"
      },
      {
        title:"Italian",
        message:it,
        locale:"it",
        flag:"/images/language_flag/flags.png"
      },
    ]
  
    return (
    // <div className="bg-white fixed right-[180px] md:right-[250px] top-[20px] z-[10000] text-sm w-[120px] rounded-sm shadow-md">
    <div className="bg-white  text-sm w-full rounded-md">
        {
          languages.map((l,index)=>{
            return <p 
            key={index}
            className={langListStyle}
            onClick={()=>
              // setSelectedLanguage({locale:"en",message:en})
              {dispatch(set_language(l))
              setClickDropDown(false)}
            }
          >
            <span><img src={l.flag} className="w-6 mr-2" /></span> 
            {l.title}
          </p>
          },[])
        }
    </div>
  )
}

export default LanguageSelection