import { FormattedMessage } from "react-intl";
import en from './languages/en.json';
import it from './languages/it.json';
// development


export const BASE_URL = process.env.NODE_ENV === "production" ? process.env.REACT_APP_PRODUCTION_URL : process.env.REACT_APP_DEVELOPMENT_URL;

// export const BASE_URL = "https://pro-id-dev-api.astutesoftwares00.com/" // Development
// export const BASE_URL = "http://192.168.0.108:8000/" // Local 

export const CLIENT_BASE_URL= "https://my.pro-id.co" //production client base url 
export const E_COMMERCE_WEBSITE_URL = "https://pro-id.co/" //Production e-commerce website


export const CLIEN_UPLOAD_RECIPT=`${E_COMMERCE_WEBSITE_URL}upload-receipt`
export const CLIENT_VIEW = `${CLIENT_BASE_URL}/profile-constant/`


export const DESCRIPTION = "description";
export const TECHNICAL_DATA = "technical data";
export const REVIEWS = "reviews";
export const COLOR_CONSTANT="color";
export const TAG_CONSTANT="tag";

export const ACCESS_TOKEN = "pro-id-admin-access-token"



// order listing limits
export const LISTING_LIMIT = 15;
// order listing limits

// custom styles
export const customStyles = {
        rows: {
            style: {
            minHeight: '72px', // override the row height
            }
        },
        headCells: {
            style: {
                "&:hover": {
                    color:"white"
                },
                backgroundColor: "rgb(128,128,128)",
                color: "white",
                padding: "10px 10px",
                fontWeight: "light",
                fontSize: "15px",
                borderRight: "1px solid #ddd",
                borderLeft: "1px solid #ddd",
                textAlign: "left"
                
            },

            activeSortStyle: {
                color: "white",
                '&:focus': {
                    outline: 'none',
                },
                '&:hover:not(:focus)': {
                    color: "white",
                },
            },
            inactiveSortStyle: {
                '&:focus': {
                outline: 'none',
                color: "white",
                },
                '&:hover': {
                    color: "white",
                },
            },
        },
        cells: {
            style: {
                borderBottom: "1px solid #ddd",
                borderRight: "1px solid #ddd",
                borderLeft: "1px solid #ddd",
                margin: "0",
                width:"150px"

            },
        },
};
// custom styles

// date display format - to be used in DateTime's /toFormat/ method
// export const DATE_FORMAT = "yyyy-MM-dd"
export const DATE_FORMAT = "yyyy-MM-dd"
export const DATE__DISPLAY_FORMAT = "dd/MM/yyyy"
export const TIME_DISPLAY_FORMAT= "hh:mm:ss a"
// date display format - to be used in DateTime's /toFormat/ method



// status constants 
export const DELIVERED='delivered'
export const CONFIRMED="confirmed"
export const SHIPPED='shipped'
export const CLOSED='closed'
export const PENDING="pending"
export const CANCELLED='cancelled'
export const ACTIVE = "active"
export const DISABLED = "disabled"
export const BANK_TRANSFER = "bank-transfer";
export const CARD_PAYMENT = "card-payment";
export const PAYPAL="paypal";

export const order_status_filters=[
    {value: "", label: "All Status"} ,
    {value: CONFIRMED, label:  "Confirmed"},
    {value: SHIPPED, label: "Shipped"},
    {value: PENDING, label: "Pending"},
    {value: CLOSED, label:  "Closed"},
    {value: CANCELLED, label: "Cancelled"}
]

export const change_order_status_options=[
    {value: CONFIRMED, label:  <FormattedMessage defaultMessage="Confirmed" id="confimed"/>},
    {value: SHIPPED, label: <FormattedMessage defaultMessage="Shipped" id="shipped"/>},
    {value: PENDING, label: <FormattedMessage defaultMessage="Pending" id="pending"/>},
    {value: CLOSED, label:  <FormattedMessage defaultMessage="Closed" id="Closed"/>},
    {value: CANCELLED, label: <FormattedMessage defaultMessage="Cancelled" id="Cancelled"/>}
]






//   for global popup
export const initialPopupState = {
    component: null,
    visible: false,
    size: "small",
    heading: "",
    contained: false,
    hideDefaultClose: false
}

export const tablesStyles = {
    rows: {
        style: {
        minHeight: '60px', // override the row height
        borderBottom: "0.8px solid #ccc",
        }
    },
    headCells:{
        style:{
            color:"#000",
            fontSize:"14px",
            // backgroundColor: "#40e0d0",
            backgroundColor: "#fff",
            fontWeight: "600"
        }
    },
    cells: {
        style: {
           color: "#000",
           fontWeight: "500",
           minWidth:'120px'
        },
    },
};
// custom styles
export const tableStylesSmoll = {
    rows: {
        style: {
        // minHeight: '60px', // override the row height
        borderBottom: "0.8px solid #ccc",
        }
    },
    headCells:{
        style:{
            color:"#000",
            fontSize:"12px",
            // backgroundColor: "#40e0d0",
            backgroundColor: "#fff",
            fontWeight: "700"
        }
    },
    cells: {
        style: {
           color: "#000",
           fontSize:"12px",
           fontWeight: "500",
           minWidth:'120px'
        },
    },
};
// custom styles

// filter constants
export const isActiveFilterOptions = [
    { 
        value: "", 
        label: "All"
    },
    {
        value: true,
        label: "Active"
    },
    {
        value: false,
        label:"Disabled"
    },
]

export const THEME_PRIMARY = "#093953"
export const THEME_SECONDARY = "#4F7284"


export const POPUP_SMALL = "small";
export const POPUP_MEDIUM = "medium";
export const POPUP_LARGE = "large";

export const SUPPORTED_IMAGES = ["image/png","image/jpeg"]

export const languageArray = [
    {
        title:"English",
        locale:"en",
        message:en,
        flag:"/images/lang-flags/uk.png"
    },
    {
        title:"Italian",
        locale:"it",
        message:it,
        flag:"/images/lang-flags/italian.png"
    }
]

export const INPUT_TYPES = [
    {
        value: "link",
        label: "link"
    },
    {
        value: "email",
        label: "email"
    },
    {
        value: "number",
        label: "number"
    },
    {
        value: "text",
        label: "text"
    },
    {
        value: "file",
        label: "file"
    },
]
export const OUTPUT_TYPES = [
    {
        value: "link",
        label: "link"
    },
    {
        value: "email",
        label: "email"
    },
    {
        value: "number",
        label: "number"
    },
    {
        value: "text",
        label: "text"
    },
]
export const PLATFORM_TYPES = [
    {
        value: "File",
        label: "File"
    },
    {
        value: "Social",
        label: "Social"
    },
    {
        value: "Portfolio",
        label: "Portfolio"
    },
    {
        value: "Contact",
        label: "Contact"
    },
    {
        value: "Featured",
        label: "Featured"
    },
    {
        value: "Finance",
        label: "Finance"
    },
]



export const DESCENDING_ORDER = "desc"
export const ASCENDING_ORDER = "asc"
export const LAHORE = "lahore"

export const BOOLEAN_OPTIONS =[
    {
        label:"Yes",
        value:true
    },
    {
        label:"No",
        value:false
    },
]

export const NORMAL_PRODUCT="normal"
// customizeable product
export const CARD_PRODUCT="customizable-card"
export const KEYCHAIN_PRODUCT="customizable-keychain"
export const MOBILE_COVER_PRODUCT="customizable-mobile-cover"
// customizeable product

export const CUSTOMIZE_PRODUCT_DROPDOWN=[
    {
        label:"Normal",
        value:NORMAL_PRODUCT
    },
    {
        label:"Custom Card",
        value:CARD_PRODUCT
    },
    {
        label:"Phone Case",
        value:MOBILE_COVER_PRODUCT
    },

]

export const BUSINESS_REQUESTS_ROUTE = "/business-requests"
export const BUSINESS_ADMIN_ROUTE = "/business-admins"
export const BUSINESS_LICENSE_ROUTE = "/business-license"
export const ADD_BUSINESS_LICENSE_ROUTE = "/business/add-license"
export const CONTACT_US_ROUTE = "/contact-us"
export const ORDERS_ROUTE = "/orders"



export const REJECTED_STATUS = {
    value: "rejected",
    label: <FormattedMessage id="rejected" defaultMessage={"Rejected"}/>,
}
export const PENDING_STATUS = {
    value: "pending",
    label: <FormattedMessage id="pending" defaultMessage={"Pending"}/>,
}
export const COMPLETED_STATUS = {
    value: "completed",
    label: <FormattedMessage id="completed" defaultMessage={"Completed"}/>,
}
export const PROCESSING_STATUS = {
    value: "processing",
    label: <FormattedMessage id="processing" defaultMessage={"Processing"}/>,
}
export const DISABLED_STATUS = {
    value: "disabled",
    label: <FormattedMessage id="disabled" defaultMessage={"Disabled"}/>,
}
export const ACTIVE_STATUS = {
    value: "active",
    label: <FormattedMessage id="active" defaultMessage={"Active"}/>,
}

export const BUSINESS_STATUS_FILTERS = [
    {value: "", label: "All Status"} ,
    REJECTED_STATUS,
    COMPLETED_STATUS,
    PENDING_STATUS,
]

export const MONTHLY_PLAN = "monthly"
export const YEARLY_PLAN = "yearly"

export const SUBSCRIPTION_PLANS = [
    {
        value: MONTHLY_PLAN,
        label: <FormattedMessage id="monthly" defaultMessage={"Monthly"}/>,
    },
    {
        value: YEARLY_PLAN,
        label: <FormattedMessage id="yearly" defaultMessage={"Yearly"}/>,
    },
]

export const WARNING = "warning"
export const INFO = "info"
export const SUCCESS = "success"
export const ERROR = "error"

export const EMAIL_REGX= /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/