
import { Store } from "react-notifications-component";
import * as yup from "yup";
import en from './languages/en.json';
import { DateTime } from "luxon";
import { ACCESS_TOKEN, initialPopupState, LAHORE, THEME_PRIMARY, THEME_SECONDARY } from "./constants";

export const getAuthUser = ()=>{

    let token = localStorage.getItem(ACCESS_TOKEN);
    if(token){
        return JSON.parse(token).user;
    }
    else{
        return false;
    }
}
export const getLanguage = ()=>{
    return {locale:"en",message:en, flag:"/images/lang-flags/uk.png"};
}

export const getToken = ()=>{
    let token = localStorage.getItem(ACCESS_TOKEN);
    if(token){
        return JSON.parse(token).access_token;
    }
    else{
        return false;
    }
}

export const setToken = (access_token)=>{
    localStorage.setItem(ACCESS_TOKEN,JSON.stringify(access_token));
}

export const removeToken = () => {
    localStorage.removeItem(ACCESS_TOKEN);
}
export const updateStorageObj=(str)=>{
    let tokenObj = localStorage.getItem(ACCESS_TOKEN);
    let parsedTokenObj=JSON.parse(tokenObj)
    parsedTokenObj.user.username = str
    localStorage.setItem(ACCESS_TOKEN,JSON.stringify(parsedTokenObj));
}


export const success_alert = (message, title) => {

    Store.addNotification({
        title: title ? title : "",
        message: message,
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 7000,
            onScreen: true,
            showIcon: true
        }
    })
}

export const error_alert = (message, title) => {
    Store.addNotification({
        title: title ? title : "",
        message: message,
        type: "danger",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 7000,
            onScreen: true,
            showIcon: true
        }
    })
}

export const warning_alert = (message, title) => {
    Store.addNotification({
        title: title ? title : "",
        message: message,
        type: "warning",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 7000,
            onScreen: true,
            showIcon: true
        }
    })
}

export function stringNotEmpty() {
    return yup.mixed().test({
        name: 'stringNotEmpty',
        exclusive: false,
        message: "Required",
        test: function(value) {
            if(value !== undefined){
                return value.trim() !== "";
            } else if(value === undefined){
                return false
            }
        },  
    });
}

export function ReplaceString(str){
    return str.replace(/\s/g, '-') 
}

export function stringIntoLowerCaseRemoveSpace(str){
    let lowerCaseString=str.toLowerCase();
    return lowerCaseString.replace(/ /g,'-')
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function removeTagsFromString(str){
    var regex = /(<([^>]+)>)/ig
    if(str){
        return (str.replace(regex, ""))
    }
}


export function GenerateString (length=9) {
    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = ' ';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
    
};


export const customConsol=(heading,value)=>{
    if((process.env.NODE_ENV === 'development') ){
       return console.log(`${heading}`, value)
    }else{
        return
    }
}

export const selectStyles = error => (
    {
        control: (provided, state) => ({
            ...provided,
            paddingTop: "2px",
            paddingBottom: "2px",
            backgroundColor: state.isDisabled ? "#fff" : "#fff",
            borderRadius: "0.375rem",
            border: state.isDisabled ? "2px solid #e4e4e4" : state.isFocused ? `2px solid ${THEME_PRIMARY}` :  state.isSelected ? "2px solid #ef4444" : state.hasValue ? `2px solid ${THEME_PRIMARY}`: error ? "2px solid #ef4444": "2px solid #cbd5e1",
            boxShadow: state.isFocused ? `0px 0px 6px ${THEME_PRIMARY}` : "none",
            "&:hover": {
                border: `2px solid ${THEME_PRIMARY}`,
                boxShadow: `0px 0px 6px ${THEME_PRIMARY}`,
            },
            fontSize: "1rem",
            "@media (max-width:1024px)": {
                fontSize: "0.875rem"
            }
            
        }),
        option: (provided, state) => {
            return ({
                ...provided,
                background: state.isSelected ? THEME_PRIMARY : "white",
                color: state.isSelected ? "#ffffff" : "#500",
                "&:hover": {
                    "background": THEME_PRIMARY,
                    color: "#ffffff"
                },
                fontSize: "1rem",
                "@media (max-width:1024px)": {
                    fontSize: "0.875rem"
                }
            })
        },
        
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';
    
            return { ...provided, opacity, transition };
        }
    }
)

export const selectStylesFilter = error => (
    {
        
        control: (provided, state) => ({
            ...provided,
            zIndex: 100,
            position:"relative",
            height: "12px",
            fontSize: "12px",
            backgroundColor: state.isDisabled ? "#fff" : "#fff",
            borderRadius: "0.375rem",
            border: state.isDisabled ? "2px solid #e4e4e4" : state.isFocused ? `2px solid ${THEME_PRIMARY}` :  state.isSelected ? "2px solid #ef4444" : state.hasValue ? `2px solid ${THEME_PRIMARY}`: error ? "2px solid #ef4444": "2px solid #cbd5e1",
            boxShadow: state.isFocused ? `0px 0px 6px ${THEME_PRIMARY}` : "none",
            "&:hover": {
                border: `2px solid ${THEME_SECONDARY}`,
                boxShadow: `0px 0px 6px ${THEME_SECONDARY}`
            },
            
        }),
        
        option: (provided, state) => {
            return ({
                ...provided,
                zIndex: 100,
            // position:"relative",
                background: state.isSelected ? THEME_PRIMARY : "white",
                color: state.isSelected ? "#FFFFFF" : "#500",
                fontSize: "12px",
                "&:hover": {
                    "background": THEME_PRIMARY,
                    color: "#FFFFFF"
                },
            })
        },
        
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';
    
            return { ...provided, opacity, transition, fontSize: "12px" };
        }
    }
)

export const hidePopup = (setState) => {
    setState(initialPopupState)
}

export const getColor=(str)=>{
    if(str.includes(",")){
        let abc=str.split(",")
        return abc[0]
    }else{
        return false
    }
}
export const getValue=(str)=>{
    let abc=str.split(",")
    if(abc.length > 1){
        return abc[1]
    } else{
        return abc[0]
    }
}
export const getColor2=(str)=>{
    let abc=str.split(",")
    if(abc.length > 1){
        return abc[0]
    } else{
        return ""
    }
}

export const replaceCommasToDots = (value) => {
    return value.replace(",", ".")
}
export const replaceDotsToCommas = (value) => {
    return value.replace(".", ",")
}

export const formatMoney=(number)=>{
    return number.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' });
}
export const calculateTax = (price,vat)=>{
    let tax = ((price) * (vat))/100
    return tax
}


export const getSperatedComma=(length,index)=>{
    if(length===1 || index === length-1 ){
        return ""
    }else  {
        return ", "
    }
}

export const getShippingFee = (city)=>{
    if(city){
        let city_slugify=city.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '');
        console.log(city_slugify)
    switch(city_slugify){
        case LAHORE:
            return 120
        default:
            return 200
    }
    }
}  


export function stripIconName(str){
    let found = str.indexOf("media/")
    if(found >= 0){
        return str.substr(found+6)
    }else{
        return str 
    }
    
}
