import { createSlice } from '@reduxjs/toolkit';

import { getAuthUser } from '../../utils';



const initialState={
    order_detail:"",
    sub_total:""
}


const orderDeatilSlice = createSlice({
    name: "Order Deatil",
    initialState,
    reducers: {
        set_order_detail:(state,action)=>{
            state.order_detail=action.payload
        },
        set_order_status:(state,action)=>{
            state.order_detail.status=action.payload
        },
        set_tracking_id:(state,action)=>{
            state.order_detail.tracking_id=action.payload
        },
        set_sub_total:(state,action)=>{
            state.sub_total=action.payload
        },
        set_all_reset : (state,action)=>{
                return initialState;       
        }
    }
})

export const {set_order_detail,set_sub_total,set_order_status,set_all_reset,set_tracking_id} = orderDeatilSlice.actions;
export default orderDeatilSlice.reducer;
