import { createSlice } from '@reduxjs/toolkit';


const initialState={
        custom_attributes_data:[]       
}


const getCustomAttributes = createSlice({
    name: "Custom Attributes",
    initialState,
    reducers: {
        set_custom_attributes: (state, action) => {
                state.custom_attributes_data =  [...state.custom_attributes_data, ...action.payload];
                return state;
        },
        add_custom_attributes: (state,action)=>{
            state.custom_attributes_data = [...state.custom_attributes_data, action.payload]
            return state
        },
        reset_custom_attributes: (state,action)=>{
            state.custom_attributes_data = initialState.custom_attributes_data
            return state
        }
    }
})

export const {set_custom_attributes,add_custom_attributes,reset_custom_attributes} = getCustomAttributes.actions;
export default getCustomAttributes.reducer;
