import { createSlice } from '@reduxjs/toolkit';

import { getAuthUser } from '../../utils';



const initialState={
        questions:[],
        total_question:0
}




const addQuestionSlice = createSlice({
    name: "Add question",
    initialState,
    reducers: {
        set_screen: (state, action) => {
                state.screen =  action.payload;
                return state;
        },
        set_question: (state, action) => {
                state.questions = [...state.questions,action.payload]
                return state;
        },
        set_all_faq: (state, action) => {
                state.questions = action.payload
                return state;
        },
        set_total_question:(state, action) => {
                state.total_question = action.payload
                return state; 
        },
        delete_question:(state,action)=>{
                state.questions = state.questions.filter((element,index) => index!==action.payload)  // we are getting index from action.payload
                return state
        },
        set_specific_question:(state,action)=>{
                state.questions[action.payload.index].tilte = action.payload.title
                state.questions[action.payload.index].answer = action.payload.answer
                return  state
        },
        reset_question : (state,action)=>{
                return initialState;       
        }
    }
})

export const {set_question,reset_question,set_total_question,delete_question,set_specific_question,set_all_faq} = addQuestionSlice.actions;
export default addQuestionSlice.reducer;
