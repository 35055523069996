import { createSlice } from '@reduxjs/toolkit';


const initialState={
        tags_data:[]       
}


const getTagsSlice = createSlice({
    name: "Categories",
    initialState,
    reducers: {
        set_tags: (state, action) => {
                state.tags_data =  [...state.tags_data, ...action.payload];
                return state;
        },
        add_tag: (state,action)=>{
            state.tags_data = [...state.tags_data, action.payload]
            return state
        },
        reset_tags: (state,action)=>{
            state.tags_data = initialState.tags_data
            return state
        },
    }
})

export const {set_tags,add_tag,reset_tags} = getTagsSlice.actions;
export default getTagsSlice.reducer;
