import React, {Suspense} from 'react';
import { useLocation } from 'react-router-dom';
import { Navigate, Route, Routes } from 'react-router';

import {FullScreenLoading} from "../components/Loading";
import { BUSINESS_ADMIN_ROUTE, BUSINESS_LICENSE_ROUTE, BUSINESS_REQUESTS_ROUTE, CONTACT_US_ROUTE } from '../constants';

// page imports
const Dashboard = React.lazy(()=>import('../pages/Dashboard'));
const Orders = React.lazy(()=>import('../pages/Orders'));
const OrderDetail = React.lazy(()=>import('../pages/Orders/OrderDetail'));
const Profile = React.lazy(()=>import('../pages/Profile'));
const Users = React.lazy(()=>import('../pages/Users'));
const UserDetail = React.lazy(()=>import('../pages/Users/UserDetail'));
const Cards = React.lazy(()=>import('../pages/Cards'));
const CardDetail = React.lazy(()=>import('../pages/Cards/CardDetail'));
const CouponCode = React.lazy(()=>import('../pages/CouponCode'));
const ProductListing = React.lazy(()=>import('../pages/ProductListing'));
const AddProduct = React.lazy(()=>import('../pages/AddProduct'));
const UpdateProduct = React.lazy(()=>import('../pages/UpdateProduct'));
const BusinessAdmins = React.lazy(()=>import('../pages/BusinessAdmins'));
const BusinessRequests = React.lazy(()=>import('../pages/BusinessRequests'));
const SocialLink = React.lazy(()=>import('../pages/SocialLinks'));
const SocialLinkDetail = React.lazy(()=>import('../pages/SocialLinkDetail'));
const AddSocialLink = React.lazy(()=>import('../pages/SocialLinks/AddLink'));
const ContactUs = React.lazy(()=>import('../pages/ContactUs'));
const BusinessLicense = React.lazy(()=>import('../pages/BusinessLicense'));



// page imports



//pages


function AdminRoutes(props) {
  const location = useLocation()

    return (
        <>
        <Suspense fallback={<FullScreenLoading/>}>
          <Routes>


          <Route path={BUSINESS_REQUESTS_ROUTE} element={<BusinessRequests />} />
          <Route path={BUSINESS_ADMIN_ROUTE} element={<BusinessAdmins />} />
          <Route path={CONTACT_US_ROUTE} element={<ContactUs/>} />
          <Route path={BUSINESS_LICENSE_ROUTE} element={<BusinessLicense/>} />


            <Route path='/dashboard' element={<Dashboard />}/>
            
            <Route path='/profile' element={<Profile />}/>


            {/* users */}
            <Route path='/users' element={<Users/>}/>
            <Route path='/user/:id' element={<UserDetail/>} />
            {/* users */}

            {/* orders */}
            <Route path='/orders' element={<Orders/> }/>
            <Route path='/order-detail/:id' element={<OrderDetail/> }/>
            {/* orders */}

             {/* orders */}
            <Route path='/cards' element={<Cards/> }/>
            <Route path='/card/:id' element={<CardDetail/> }/>
            {/* orders */}


            {/* Coupon Codes */}
            <Route path='/coupon-codes' element={<CouponCode/> }/>
            {/* Coupon Codes */}


            {/* prroduct*/}
            <Route path='/products' element={ <ProductListing/>  }/>
            <Route path='/add-product' element={ <AddProduct/>  }/>
            <Route path='/update-product/:id' element={ <UpdateProduct/>  }/>
            {/* prroduct */}




            
            {/* social link */}
            <Route path='/social-links' element={<SocialLink/>}/>
            <Route path='/social-links/add' element={<AddSocialLink/>}/>
            <Route path='/social-links/:id' element={<SocialLinkDetail/>}/>
            {/* social link */}

            <Route path='*' element={<Navigate to='/dashboard' /> }/>
            
          </Routes>

          {/* <Footer /> */}
        </Suspense>
        </>
    )
}

export default AdminRoutes

