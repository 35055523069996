import { createSlice } from '@reduxjs/toolkit';


const initialState={
        refresh:0      
}


const updateProductSlice = createSlice({
    name: "update_product",
    initialState,
    reducers: {
        increment_count: (state, action) => {
                state.refresh =  state.refresh + 1;
                return state;
        },
    }
})

export const {increment_count} = updateProductSlice.actions;
export default updateProductSlice.reducer;
