import { createSlice } from '@reduxjs/toolkit';

import { getAuthUser } from '../../utils';



const initialState={
        general:{
                product_name:"",
                // slug:"",
                description:"",
                category:"",
                tags:"",
                reg_price:"",
                sale_price:"",
                sales_start_date:"",
                sales_end_date:"",
                priceInOffer_start_Date:"",
                priceInOffer_end_Date:"",
                list_price:"",
                offer_price:"",
                tax_state:"",
                tax_range:"",
                visibility:"",
                product_type:"",
                image:"",
                tax:"",
                background_image1:"",
                background_image2:"",
                extraImage1:"",
                extraImage2:"",
                extraImage3:"",
                
        },
        Inventory:{
                sku:"",
                stk_quantity:"",
                low_stock_threshold:"",
                allow_backorder:false,
        },
        shipping:{
                weigth:"",
                length:"",
                width:"",
                heigth:"",
                shipping_fee:"",
        },
        attributes:{
                custom_attributes:[]                
        },
        advance_selection:{
                purchase_note:"",
                enable_reviews:false
        },
        seo_section:{
                meta_title:"",
                meta_description:""
        },
        dynamic_label_for_shipping:" - ",
        screen: 0
}


const addProductSlice = createSlice({
    name: "Add product",
    initialState,
    reducers: {
        set_screen: (state, action) => {
                state.screen =  action.payload;
                return state;
        },
        set_general: (state, action) => {
                state.general = {...state.general ,...action.payload}
                return state;
        },
        set_inventory: (state, action) => {
                state.Inventory = {...state.Inventory ,...action.payload}
                return state;
        },
        set_shipping: (state, action) => {
                state.shipping = {...state.shipping ,...action.payload}
                return state;
        },
        set_attributes: (state, action) => {
                state.attributes = {...state.attributes ,...action.payload}
                return state;
        },
        set_advnce_selection: (state, action) => {
                state.advance_selection = {...state.advance_selection ,...action.payload}
                return state;
        },
        set_seo_section: (state, action) => {
                state.seo_section = {...state.seo_section ,...action.payload}
                return state;
        },
        set_dynamic_label:(state,action)=>{
                state.dynamic_label_for_shipping =  action.payload;
                return state;
        },
        set_all_reset : (state,action)=>{
                return initialState;       
        }
    }
})

export const {set_screen,set_general,set_all_reset,set_advnce_selection,set_attributes,set_inventory,set_shipping,set_dynamic_label,set_seo_section} = addProductSlice.actions;
export default addProductSlice.reducer;
